<script>
import TableView from '@/components/TableVIew'
import { getAction } from '@/command/netTool'

export default {
  name: 'userManagement',
  components: { TableView },
  props: {
    acData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentPage: 1,
      dataSource: [],
      dataSource1: [],
      objInfo: {
        numberOfParticipants: 0,
        numberOfInvitees: 0
      }
    }
  },
  methods: {
    getColumns() {
      return [
        {
          title: '用户',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '联系方式',
          dataIndex: 'telephone',
          align: 'center'
        },
        {
          title: '有效邀请数量',
          dataIndex: 'num',
          align: 'center'
        }
      ]
    },
    changePage({ current }) {
      this.currentPage = current
    },
    getInit() {
      const { id } = this.acData
      getAction(`/activities/statistics/${id}`).then(res => {
        const { data } = res
        if (data) {
          const { users, awards, numberOfInvitees, numberOfParticipants } = data
          this.objInfo = {
            numberOfParticipants,
            numberOfInvitees
          }
          this.dataSource = users
          this.dataSource1 = awards
        }
      })
    },
    renderData() {
      return this.dataSource1.map(e => {
        return {
          value: e.number,
          name: `${e.goodsName}${e.goodsUnit ? '(' + e.goodsUnit + ')' : ''}`
        }
      })
    }
  },
  mounted() {
    const { id } = this.acData
    if (id) {
      this.getInit()
    }
  },
  render() {
    return (
      <div class="user-box">
        <div class="top-box">
          <div class="top-box-div">
            <span>{this.objInfo.numberOfParticipants}</span>
            <span>参与人数</span>
          </div>
          <div class="top-box-div">
            <span>{this.objInfo.numberOfInvitees}</span>
            <span>邀请总人数</span>
          </div>
        </div>
        <div class="middle-box">
          {this.renderData().map(e => {
            return (
              <div class="middle-box-div">
                <span>{e.value ?? 0}</span>
                <span>{e.name}</span>
              </div>
            )
          })}
        </div>
        <div class="bottom-box">
          <TableView
            bordered
            columns={this.getColumns()}
            dataSource={this.dataSource}
            showRowSelect={false}
            pagination={{
              showSizeChanger: false,
              defaultPageSize: 7,
              hideOnSinglePage: true,
              showQuickJumper: false,
              hideOnSinglePage: true,
              current: this.currentPage
            }}
            onChange={this.changePage}
          />
        </div>
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
.user-box {
  width: 100%;
  height: 100%;

  .top-box {
    width: 100%;
    height: 100px;
    border: 1px solid rgba(232, 234, 236, 1);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      &:nth-last-of-type(1) {
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        width: 1px;
        height: 52px;
        background-color: rgba(232, 234, 236, 1);
        position: absolute;
        right: 0;
      }

      span {
        font-family: 'PingFang SC ', 'PingFang SC', sans-serif;
        font-weight: 500;
        font-style: normal;
        color: #515a6e;
        &:nth-of-type(1) {
          font-size: 20px;
        }
        &:nth-of-type(2) {
          font-size: 14px;
        }
      }
    }
  }

  .middle-box {
    width: 100%;
    min-height: 100px;
    border: 1px solid rgba(232, 234, 236, 1);
    border-radius: 4px;
    margin: 15px auto 15px;
    display: flex;
    flex-wrap: wrap;

    &-div {
      width: 25%;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0 10px;
      box-sizing: border-box;

      span {
        text-align: center;
        width: 100%;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }

      &:nth-last-of-type(1),
      &:nth-child(4n) {
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        width: 1px;
        height: 52px;
        background-color: rgba(232, 234, 236, 1);
        position: absolute;
        right: 0;
      }

      span {
        font-family: 'PingFang SC ', 'PingFang SC', sans-serif;
        font-weight: 500;
        font-style: normal;
        color: #515a6e;
        &:nth-of-type(1) {
          font-size: 20px;
        }
        &:nth-of-type(2) {
          font-size: 14px;
        }
      }
    }
  }

  .bottom-box {
    /deep/ .ant-table-pagination {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
